<template>
  <div>
    <div class="text-left">
      <div class="d-flex justify-content-between">
        <h3 class="f-bold cl-primary mb-4">My packages</h3>
        <p
          class="cl-primary align-self-center"
          v-on:click="toCreate()"
          style="cursor: pointer"
        >
          Submit Packages
        </p>
      </div>

      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>
      <div v-if="!loading">
        <!-- <div v-if="data.length == 0">
          <p class="text-secondary">Packages is empty</p>
        </div> -->
        <div
          class="row b-bottom pt-3 row-hovered"
          v-for="(packages, p) in data"
          :key="p"
        >
          <div
            class="col-md-12"
            style="cursor: pointer"
            v-on:click="toDetail(packages.code)"
          >
            <h6>
              <span class="cl-primary f-bold">{{ packages.code }}</span>
              <span class="text-secondary title-text">
                | Approval : {{ packages.status_approval }}</span
              >
            </h6>
            <p class="cl-secondary">
              Description: {{ packages.package_detail.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  name: "MyPackages",
  data() {
    return {
      data: [],
      loading: true,
    };
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    toCreate() {
      this.$router.push("/customers/submit-packages");
    },

    toDetail(code) {
      this.$router.push("/customers/detail-packages/" + code);
    },

    toList(detailStatus, createStatus) {
      this.detailStatus = detailStatus;
      this.createStatus = createStatus;
      console.log(createStatus);
      window.scrollTo(0, 0);
      this.getPackages();
    },

    getPackages() {
      this.loading = true;
      const endpoint = "customer-package/customer";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.customer_package;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },
  },

  mounted() {
    this.getPackages();
    this.detailStatus = false;
    this.scrollToTop();
  },
};
</script>
